import React, { useState, useRef } from "react"
import Layout from "../../components/layout"
import {Typography, Container } from "@mui/material"


const WebARRoom = () => {




  return (
    <Layout>
      <Container
        maxWidth="fit-content"
        disableGutters
        sx={{
          position: "absolute",
          top: "0",
        }}
      >
        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontFamily: "clash display, sans-serif",
            fontSize: { xs: "1.5rem", md: "3rem" },
            color: "black",
            textAlign: "center",
            lineHeight: "150%",
            marginTop: "90px",
            fontWeight: "600",
          }}
        >
          WebVR Room
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          Go Immerse Virtual Tour 
        </Typography>
      </Container>

      <Container
        sx={{
          width: "100vw",
          padding: "0",
          paddingLeft: "0",
          paddingRight: "0",
          maxWidth: "fit-content",
        }}
        maxWidth="fit-content"
        disableGutters
      >
        <iframe
          style={{ width: "100vw", height: "100vh", borderStyle: "none" }}
          src="https://neoxr.s3.dualstack.us-east-2.amazonaws.com/360room/index.html"
          title="description"
        ></iframe>
      </Container>
    </Layout>
  )
}

export default WebARRoom
